import { useState, useEffect } from "react";
import useApi from "./api";

const useUserProfile = () => {
  const { fetchUserProfile, updateUserProfile } = useApi();

  const [profile, setProfile] = useState({
    age: "",
    weight: "",
    height: "",
    gender: "",
    activityLevel: "",
    bodyFat: "",
    dailyLogs: [], // Array to store daily logs
  });

  const [dailyLog, setDailyLog] = useState({
    date: new Date().toISOString().split("T")[0], // Default to today's date
    weight: "",
    calories: "",
  });

  useEffect(() => {
    const loadProfile = async () => {
      try {
        const data = await fetchUserProfile();
        setProfile(data);
      } catch (error) {
        console.error("Failed to load profile:", error);
      }
    };
    loadProfile();
  }, [fetchUserProfile]);

  const handleProfileChange = (event) => {
    setProfile({
      ...profile,
      [event.target.name]: event.target.value,
    });
  };

  const handleDailyLogChange = (event) => {
    setDailyLog({
      ...dailyLog,
      [event.target.name]: event.target.value,
    });
  };

  const handleProfileSubmit = async () => {
    try {
      await updateUserProfile(profile);
      console.log("Profile updated successfully");
    } catch (error) {
      console.error("Failed to update profile:", error);
    }
  };

  const handleDailyLogSubmit = async () => {
    try {
      const updatedProfile = {
        ...profile,
        dailyLogs: [...profile.dailyLogs, dailyLog],
      };
      await updateUserProfile(updatedProfile);
      setProfile(updatedProfile);
      console.log("Daily log updated successfully");
    } catch (error) {
      console.error("Failed to update daily log:", error);
    }
  };

  return {
    profile,
    dailyLog,
    handleProfileChange,
    handleDailyLogChange,
    handleProfileSubmit,
    handleDailyLogSubmit,
  };
};

export default useUserProfile;
