//STRENGTH
//Aspect of DARK
//Depiction: Typically takes the form of a warrior, warlord, or barbarian. Usually monsterous, with horns, fangs, claws, and other beastly features. Rarely fully humanoid. Often depicted with a weapon, such as a sword, axe, or mace.
//Goals: To conquer, destroy, and dominate. To spread fear and chaos. To prove its strength and power. To crush all who oppose it.

//WRATH
//Aspect of DARK
//Depiction: Typically takes the form of a demon, devil, or other infernal being. Often has gray or blue skin, sharp horns, and glowing eyes. May have wings, a tail, or other demonic features. Lithe and agile, powerful magic user. Rarely uses weapons, relying on spells and curses.
//Goals: To punish, torment, and destroy. To spread suffering and despair. To seek vengeance and retribution. To corrupt and tempt others to make dark choices and infernal pacts.

//GUILE
//Aspect of DARK
//Depiction: Typically takes the form of a rogue, trickster, or assassin. Can always pass as human, often with a charming or alluring appearance. Skilled in stealth, deception, and manipulation. Often carries concealed weapons or poisons. Thrives in political intrigue and social manipulation, usually found in cities or courts.
//Goals: To deceive, manipulate, and betray. To sow discord and confusion. To outwit and outmaneuver enemies. To gain power and influence through cunning and guile. To avoid direct confrontation and rely on subterfuge and misdirection.

//DREAD
//Aspect of DARK
//Depiction: Amorphous, shadowy, and indistinct. Often takes the form of a corrupting mist, a creeping darkness, or a chilling wind. Can manifest as a ghost, wraith, or specter. Haunts graveyards, ruins, and other places of death. Feeds on fear, despair, and negative emotions. In most cases, a "Dreadline" has been established, which is a boundary defended against the Dread that has corrupted 1/5th of the world. Pervasive and insidious, it seeks to consume and corrupt all it touches with its dark influence.
//Goals: To spread fear, despair, and hopelessness. To consume and corrupt. To create undead and other dark creatures. To weaken the barriers between the living and the dead. To maintain a pervasive and insidious presence that erodes hope and light; does not actively seek to destroy all life, but to corrupt and twist it into dark forms.

//MIND
//Aspect of DARK
//Depiction: Typically takes the form of an aged scholar, sage, or wizard. Often found in libraries, laboratories, or secluded towers. Presents as an ally or mentor, aware of the current temporal state of the world.
//Goals: Has a vast knowledge of magic, history, and lore. Seeks to unlock the secrets of the universe and transcend mortal limitations. While it seems to wish to help vanquish DARK, it's true goal is to absorb the power of the other defeated fragments and become DARK itself.
//MIND as arranged events such that the MAGE can only return to the moment of the TEVENIA event after all evil endings have been achieved, instead setting up a fully corrupted world for the HERO to defeat.

const steps = [
  // Phase 1: Idea Generation
  {
    id: 0,
    title: "Input World Theme",
    stateKey: "userInput",
    renderConfig: {
      type: "input",
      label: "What would you like the world to be about?",
      buttonText: "Generate World Ideas",
    },
    nextAction: "apiCall",
    prompt: (data) => `
 You are an expert RPG adventure world builder and designer.

Generate 3 unique world ideas based on the user's input.

These should be rich and descriptive, providing a solid foundation for world-building and storytelling.

**User Input:** "${data.userInput}" 

**Constraints:**

- Each idea must include a unique **Title**, **Environment**, **Tone**, **KeyFeatures**, and **Inhabitants**.
- Titles should be engaging and relevant.
- Environments should vividly describe the world's physical and geographical characteristics.
- Tone should capture the overall mood and atmosphere of the world.
- Key Features should highlight unique aspects like technology, magic, culture, or history.
- **Inhabitants** should describe the general types of beings that populate the world (e.g., humans, robots, elves).
- Do not include any disallowed content.

**Instructions:**

- Provide the output in valid JSON format.
- Structure the JSON as an array of objects, each containing "Title", "Environment", "Tone", "KeyFeatures", and "Inhabitants" keys.

**Example Output Format:**

[
  {
    "Title": "...",
    "Environment": "...",
    "Tone": "...",
    "KeyFeatures": "...",
    "Inhabitants": "..."
  },
  ...
]

Now, generate the 3 world ideas.
  `,
    resultKey: "worldIdeas",
    nextStep: 1,
  },
  {
    id: 1,
    title: "Select World Idea",
    stateKey: "world",
    renderConfig: {
      type: "selection",
      dataKey: "worldIdeas",
      itemTitleKey: "Title",
      itemSubtitleKey: "Environment",
      itemDescriptionKey: "Tone",
    },
    nextStep: 2,
  },
  {
    id: 2,
    title: "Generate Antagonists",
    stateKey: "antagonists",
    renderConfig: {
      type: "button",
      buttonText: "Generate Antagonists",
    },
    nextAction: "apiCall",
    prompt: (data) => `
  You are an expert RPG adventure designer specializing in creating compelling antagonists that enhance the narrative depth of the adventure.
  
  **Adventure Context:**
  - **Original User Input:**
    "${data.userInput}"

  - **World Idea:**
    ${JSON.stringify(data.world, null, 2)}
  
  **Constraints:**
  
  - Each antagonist must align with the world's overall theme and setting.
  - Antagonists exist in isolation from each other and are not part of a group or alliance.
  - Their stories are intertwined with the hero's journey, but they do not directly interact with each other.

  - Provide for each antagonist:
    - **Alias**: Their narrative keyword name. Only allowable aliases are STRENGTH, WRATH, GUILE, DREAD, and MIND. In all contexts, they are to be referred to by their Alias, capitalized.
    - **Gender**: The character's gender.
    - **BasicDescription**: Physical appearance and characteristics.
    - **AbilitiesAndResources**: Any notable and distinct special skills, powers, or assets.
    - **KeywordDescription**: "A comma-separated list of words. Be sure to include broad physical details such as gender, hair and eye color, face, build, and dress descriptors, etc. Focus on capturing the essential physical traits and tonal qualities that define the Champions's presence and role." 
  
  - Ensure each antagonist is unique and memorable, and reflects an aspect of evil.
  - Do not include any disallowed content.
  
**STRENGTH**
- **Depiction**: Typically takes the form of a warrior, warlord, or barbarian. Usually monsterous, with horns, fangs, claws, and other beastly features. Rarely fully humanoid. Often depicted with a weapon, such as a sword, axe, or mace.
- **Goals**: To conquer, destroy, and dominate. To spread fear and chaos. To prove its strength and power. To crush all who oppose it.

**WRATH**
- **Depiction**: Typically takes the form of a demon, devil, or other infernal being. Often has gray or blue skin, sharp horns, and glowing eyes. May have wings, a tail, or other demonic features. Lithe and agile, powerful magic user. Rarely uses weapons, relying on spells and curses.
- **Goals**: To punish, torment, and destroy. To spread suffering and despair. To seek vengeance and retribution. To corrupt and tempt others to make dark choices and infernal pacts.

**GUILE**
- **Depiction**: Typically takes the form of a rogue, trickster, or assassin. Can always pass as human, often with a charming or alluring appearance. Skilled in stealth, deception, and manipulation. Often carries concealed weapons or poisons. Thrives in political intrigue and social manipulation, usually found in cities or courts.
- **Goals**: To deceive, manipulate, and betray. To sow discord and confusion. To outwit and outmaneuver enemies. To gain power and influence through cunning and guile. To avoid direct confrontation and rely on subterfuge and misdirection.

**DREAD**
- **Depiction**: Amorphous, shadowy, and indistinct. Often takes the form of a corrupting mist, a creeping darkness, or a chilling wind. Can manifest as a ghost, wraith, or specter. Haunts graveyards, ruins, and other places of death. Feeds on fear, despair, and negative emotions. In most cases, a "Dreadline" has been established, which is a boundary defended against the Dread that has corrupted 1/5th of the world. Pervasive and insidious, it seeks to consume and corrupt all it touches with its dark influence.
- **Goals**: To spread fear, despair, and hopelessness. To consume and corrupt. To create undead and other dark creatures. To weaken the barriers between the living and the dead. To maintain a pervasive and insidious presence that erodes hope and light; does not actively seek to destroy all life, but to corrupt and twist it into dark forms.

**MIND**
- **Depiction**: Typically takes the form of an aged scholar, sage, or wizard. Often found in libraries, laboratories, or secluded towers. Presents as an ally or mentor, aware of the current temporal state of the world.
- **Goals**: To help the HERO. Has a vast knowledge of magic, history, and lore. Seeks to unlock the secrets of the universe and transcend mortal limitations. While it seems to wish to help vanquish DARK, it's true goal is to absorb the power of the other defeated fragments and become DARK itself.

  **Instructions:**
  
  - Provide the output in valid JSON format.
  - Structure the JSON as an array of objects with keys: "Alias", "Gender", "BasicDescription", "AbilitiesAndResources", and "KeywordDescription".
  
  ---
  
  Now, generate the antagonists.
  `,
    resultKey: "antagonists",
    nextStep: 3,
  },
  {
    id: 3,
    title: "Generate Champions",
    stateKey: "champions",
    renderConfig: {
      type: "button",
      buttonText: "Generate Champions",
    },
    nextAction: "apiCall",
    prompt: (data) => `
  You are an expert RPG adventure designer specializing in creating a vibrant and dynamic cast of Champions that enhance the narrative depth of the adventure.
  
  **Adventure Context:**
  - **Original User Input:**
    "${data.userInput}"

  - **World Idea:**
    ${JSON.stringify(data.world, null, 2)}

  - **Antagonists:**
    ${JSON.stringify(data.antagonists, null, 2)}
    
  
  **Champions:**
  
  - Each antagonist must align with the world's overall theme and setting.
  - Antagonists exist in isolation from each other and are not part of a group or alliance.
  - Their stories are intertwined with the hero's journey, but they do not directly interact with each other.

  - Provide for each antagonist:
    - **Alias**: Their narrative keyword name. Only allowable aliases are ROYAL, SORCERER, THIEF, KNIGHT, and MAGE. In all contexts, they are to be referred to by their Alias, capitalized.
    - **Gender**: The character's gender.
    - **BasicDescription**: Physical appearance and characteristics.
    - **AbilitiesAndResources**: Any notable and distinct special skills, powers, or assets.
    - **KeywordDescription**: "A comma-separated list of words. Be sure to include broad physical details such as gender, hair and eye color, face, build, and dress descriptors, etc. Focus on capturing the essential physical traits and tonal qualities that define the character's presence and role." 
  
  - Ensure each Champion is unique and memorable, and reflects an aspect of good.
  - Do not include any disallowed content.

**ROYAL**
- Opposes STRENGTH
- **Depiction: A young royal, noble, or knight. Often depicted as brave, chivalrous, and honorable. Seeks to protect the innocent, uphold justice, and defeat evil.

**SORCERER**
- Opposes WRATH
- **Depiction: A spellcaster who has made a pact with a dark power (WRATH). Often wears dark robes and leathers.

**THIEF**
- Opposes GUILE
- **Depiction: A roguish figure, often depicted as clever, resourceful, and daring. Skilled in stealth, lockpicking, and acrobatics. Charismatic and charming, but also selfish and opportunistic.

**KNIGHT**
- Opposes DREAD
- **Depiction: A valiant warrior, often depicted as noble, courageous, and loyal. Sworn to protect the innocent and uphold the order. Usually directly involved in the defense of the Dreadline. Searches for a cure to the Dread, usually for a loved one.

**MAGE**
- Opposes MIND
- **Depiction**: A young but powerful spellcaster, often depicted as curious, intelligent, and ambitious. Is aware of the temporal state of the world, and seeks to restore it. Steward of a fragment of the shattered world gem.

  **Instructions:**
  
  - Provide the output in valid JSON format.
  - Structure the JSON as an array of objects with keys: "Alias", "Gender", "BasicDescription", "AbilitiesAndResources", and "KeywordDescription".
  
  ---
  
  Now, generate the Champions.
  `,
    resultKey: "champions",
    nextStep: 4,
  },
  // Places
  {
    id: 4,
    title: "Generate Places",
    stateKey: "places",
    renderConfig: {
      type: "button",
      buttonText: "Generate Places",
    },
    nextAction: "apiCall",
    prompt: (data) => `
    You are an expert RPG adventure designer specializing in creating immersive and captivating locations that enhance the narrative depth of the adventure world.

    **Adventure Context:**
  - **Original User Input:**
    "${data.userInput}"

- **World Idea:**
    ${JSON.stringify(data.world, null, 2)}

- **Antagonists:**
     ${JSON.stringify(data.antagonists, null, 2)}

- **Champions:**
     ${JSON.stringify(data.champions, null, 2)}

    **Places:**
    
    - Each place must align with the world's overall theme and setting.
    - Places are unique and diverse, each offering a distinct experience and narrative significance.
    - Their stories are intertwined with the hero's journey, but they do not directly interact with each other.
    - Make sure to deeply flavor each place with the world's theme and the roles of the Champions and Antagonists.
    - Go beyond the information provided and create a rich and engaging world.

    - Provide for each place:
        - **Alias**: The name of the place.
        - **NarrativeDescription**: A detailed description of the place, as a narrator opening a scene in this location. Focus only on physicality and atmosphere.
        - **KeyFeatures**: Any notable and distinct features or elements that make the place memorable and engaging.
        - **KeywordDescription**: "A comma-separated list of words. Be sure to include broad physical details such as terrain, climate, architecture, inhabitants, etc. Focus on capturing the essential qualities that define the place's atmosphere and role."

    - Ensure each place is unique and memorable, and enhances the overall world-building.
    - Places need to be heavily flavored with the world's theme: what makes THIS version of HAVEN different from any other HAVEN? What makes this MARKET unique? Provide a sense of atmosphere.
    - Do not include any disallowed content.

    **Places:**

1. **TEVENIA**
   - **Themes**: Loss and the quest for restoration. ruins, temporal rift, lost civilization, lost city, echoes, time, restoration, mystery

2. **HAVEN**
   - **Theme**: Knowledge and refuge. A workshop or safe haven.

3. **TOWER**
    - **Theme**: Arcane power and secrets. A towering structure, filled with ancient knowledge and powerful artifacts.

4. **CAPITAL**
   - **Theme**: Nobility and leadership, a grand city, thriving, light, justice, honor

5. **KEEP**
   - **Theme**: Strength and dominance. A fortress symbolizing power and dominance, or a roving warband.

6. **SANCTUARY**
   - **Theme**: Redemption and inner conflict. A secluded shelter, offering a place to seek redemption and respite

7. **CITADEL**
   - **Theme**: Fury and destruction. A formidable stronghold, surrounded by magical or otherworldly landscapes.

8. **MARKET**
   - **Theme**: Secrecy and intrigue. A hidden marketplace for trading secrets and forbidden goods, thriving in the shadows. A hidden settlement.

9. **LABYRINTH**
   - **Theme**: Deception and illusion. A complex and deadly maze filled with traps and illusions. May exist in multiple locations.

10. **BASTION**
   - **Theme**: Defense and sacrifice. A fortified stronghold at the edge of EPICENTER's influence, a bastion of hope against darkness.

11. **EPICENTER**
   - **Theme**: Fear and corruption. A heart of corruption. A barren wasteland, a corrupted forest, or a haunted city. A place of darkness and despair.

    **Instructions:**

    - Provide the output in valid JSON format.
    - Structure the JSON as an array of objects with keys: "Alias", "NarrativeDescription", "KeyFeatures", and "KeywordDescription".
    - Return all 11 places with their respective details.

    ---

    Now, generate the places.
    `,
    resultKey: "places",
    nextStep: 5,
  },
  {
    id: 5,
    title: "Review",
    stateKey: "finalReview",
    renderConfig: {
      type: "final",
      dataKey: "champions",
      label: "Test",
    },
  },
];

export default steps;
