import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Grid,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import useUserProfile from "./useUserProfile";

const calculateTDEE = (profile, unit) => {
  let { weight, height, age, gender, activityLevel } = profile;
  let bmr;

  // Convert weight and height to metric if they are in imperial
  if (unit === "imperial") {
    weight = weight * 0.453592; // lbs to kg
    height = height * 2.54; // inches to cm
  }

  if (gender === "male") {
    bmr = 88.362 + 13.397 * weight + 4.799 * height - 5.677 * age;
  } else {
    bmr = 447.593 + 9.247 * weight + 3.098 * height - 4.33 * age;
  }

  const activityMultiplier = {
    sedentary: 1.2,
    lightlyActive: 1.375,
    moderatelyActive: 1.55,
    veryActive: 1.725,
    extraActive: 1.9,
  };

  return bmr * activityMultiplier[activityLevel];
};

const UserProfile = () => {
  const [unit, setUnit] = useState("metric");
  const {
    profile,
    dailyLog,
    dailyLogs,
    handleProfileChange,
    handleDailyLogChange,
    handleProfileSubmit,
    handleDailyLogSubmit,
  } = useUserProfile();

  const tdee = calculateTDEE(profile, unit);

  const handleUnitChange = (event) => {
    setUnit(event.target.value);
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        User Profile
      </Typography>
      <RadioGroup row value={unit} onChange={handleUnitChange}>
        <FormControlLabel
          value="metric"
          control={<Radio />}
          label="Metric (kg, cm)"
        />
        <FormControlLabel
          value="imperial"
          control={<Radio />}
          label="Imperial (lbs, inches)"
        />
      </RadioGroup>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Age"
            name="age"
            value={profile.age}
            onChange={handleProfileChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={`Weight (${unit === "metric" ? "kg" : "lbs"})`}
            name="weight"
            value={profile.weight}
            onChange={handleProfileChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={`Height (${unit === "metric" ? "cm" : "inches"})`}
            name="height"
            value={profile.height}
            onChange={handleProfileChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            label="Gender"
            name="gender"
            value={profile.gender}
            onChange={handleProfileChange}
          >
            <MenuItem value="male">Male</MenuItem>
            <MenuItem value="female">Female</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            label="Activity Level"
            name="activityLevel"
            value={profile.activityLevel}
            onChange={handleProfileChange}
          >
            <MenuItem value="sedentary">Sedentary</MenuItem>
            <MenuItem value="lightlyActive">Lightly Active</MenuItem>
            <MenuItem value="moderatelyActive">Moderately Active</MenuItem>
            <MenuItem value="veryActive">Very Active</MenuItem>
            <MenuItem value="extraActive">Extra Active</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleProfileSubmit}
          >
            Save Profile
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">
            Estimated TDEE: {tdee.toFixed(2)} kcal/day
          </Typography>
        </Grid>
      </Grid>

      <Typography variant="h5" gutterBottom style={{ marginTop: "20px" }}>
        Daily Log
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Date"
            name="date"
            type="date"
            value={dailyLog.date}
            onChange={handleDailyLogChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={`Weight (${unit === "metric" ? "kg" : "lbs"})`}
            name="weight"
            value={dailyLog.weight}
            onChange={handleDailyLogChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Calories"
            name="calories"
            value={dailyLog.calories}
            onChange={handleDailyLogChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDailyLogSubmit}
          >
            Save Daily Log
          </Button>
        </Grid>
      </Grid>

      <Typography variant="h5" gutterBottom style={{ marginTop: "20px" }}>
        Calorie Intake Graph
      </Typography>
      <LineChart
        width={500}
        height={300}
        data={dailyLogs}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="calories"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
        />
        <Line
          type="monotone"
          dataKey="TDEE"
          stroke="#82ca9d"
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </Container>
  );
};

export default UserProfile;
