import { getAwsUrl } from "../EnvHelper";
import { useAuth0 } from "@auth0/auth0-react";

const useApi = () => {
  const { getAccessTokenSilently } = useAuth0();

  const fetchUserProfile = async () => {
    try {
      const endpoint =
        getAwsUrl("REACT_APP_DRAGONSCALES_ENDPOINT") + "/tdee/profile";
      return await apiRequest(endpoint, "GET");
    } catch (error) {
      console.error("Error fetching profile:", error);
      throw error;
    }
  };

  const updateUserProfile = async (profile) => {
    try {
      const endpoint =
        getAwsUrl("REACT_APP_DRAGONSCALES_ENDPOINT") + "/tdee/profile";
      return await apiRequest(endpoint, "POST", profile);
    } catch (error) {
      console.error("Error updating profile:", error);
      throw error;
    }
  };

  const apiRequest = async (endpoint, method = "GET", payload = null) => {
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: "read:current_user email profile",
        },
      });

      const response = await fetch(endpoint, {
        method,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: payload ? JSON.stringify(payload) : null,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return await response.json();
    } catch (error) {
      console.error("API request failed:", error);
      throw error;
    }
  };

  return { fetchUserProfile, updateUserProfile };
};

export default useApi;
